<template>
    <o-data-lookup :data-object="dsLookupDataObject" contextField="OrgUnitIdPath">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        
        <o-column field="ID" width="80"></o-column>
        <o-column field="TypeAndName" width="300"></o-column>
        <o-column field="Description" width="300"></o-column>
        <o-column field="OrgUnit" width="180"></o-column>
    </o-data-lookup>
</template>

<script setup>
    import { computed, ref, watch, watchEffect } from 'vue';
    import { getOrCreateDataObject } from 'o365.vue.ts'

    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });


    const dsLookupDataObject = getOrCreateDataObject({
        id: 'dsLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Complete_Activities',
        maxRecords: 25,
        whereClause: "Deleted IS NULL",
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "TypeAndName", type: "string", sortOrder: 1, sortDirection: "asc" },             
             {name: "Name", type: "string" },                 
             {name: "Description", type: "string" },      
             {name: "OrgUnit", type: "string" }]
    }); 
    
     
</script>